<template>
  <el-form  ref="ruleForm" :model="bargeFormData" >
    <div class="flex-between-row margin-right-10">
      <h1 class="sub-module-title" >{{title}}</h1>
      <el-button type="primary" round size="mini" v-if="termContractType===TERM_CONTRACT_TYPE.COA"  @click="addNewLine" :disabled="isAllFieldsDisabled" >
        Add
      </el-button>
    </div>
    <div class="sub-module-block-table">
      <div class="div-product-other-costs-info-body">
        <el-table ref="bargeTable" :data="bargeFormData.barges" border>
          <el-table-column label="No." width="50px">
            <template v-slot="scope">
              <h5  class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
            </template>
          </el-table-column>
          <el-table-column min-width="150px">
            <template v-slot:header><span>* </span>Barge Name</template>
            <template v-slot="scope">
              <el-form-item :prop="'barges.'+scope.$index+'.id'" :rules="rules.notReqRule">
                <!-- <el-select
                  v-model="scope.row.name"
                  value-key="id"
                  :disabled="isAllFieldsDisabled"
                  @change="handleSelectBarge($event,scope.$index)"
                >
                  <el-option
                    v-for="item in bargeOptionList"
                    :key="item.id" :label="item.shipName" :value="item.id">
                  </el-option>
                </el-select> -->
                <el-select
                  popper-class="custom-ship-selector"
                  value-key="id"
                  style="width: 100%"
                  v-model="scope.row.id"
                  @change="handleSelectBarge($event,scope.$index)"
                  :disabled="isAllFieldsDisabled">
                    <span style="display: inline-block; width: 200px; font-size: 14px; font-weight: 500; margin-left: 20px; margin-bottom: 10px;">Name</span>
                    <span style="display: inline-block; width: 100px; font-size: 14px; font-weight: 500;">IMO</span>
                    <span style="display: inline-block; font-size: 14px; font-weight: 500;">SB No.</span>
                  <el-option
                    v-for="item in bargeOptionList"
                    :key="item.id"
                    :label="item.shipName"
                    :value="item.id">
                      <span style="display: inline-block; width: 200px; font-size: 12px;">{{item.shipName}} </span>
                      <span style="display: inline-block; width: 100px; font-size: 12px;">{{item.imo}}</span>
                      <span style="display: inline-block; font-size: 12px;">{{item.licenceNo}}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="100px">
            <template v-slot:header>IMO No</template>
            <template v-slot="scope">
              <el-form-item :prop="'barges.'+scope.$index+'.imoNo'" :rules="rules.notReqRule">
                <el-input v-model="scope.row.imoNo" disabled/>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="100px">
            <template v-slot:header>SB No.</template>
            <template v-slot="scope">
              <el-form-item :prop="'barges.'+scope.$index+'.sbNo'" :rules="rules.notReqRule">
                <el-input v-model="scope.row.sbNo" disabled/>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column min-width="200px" v-if="termContractType === TERM_CONTRACT_TYPE.COA">
            <template v-slot:header><span>* </span>Date Range</template>
            <template v-slot="scope">
              <el-form-item :prop="'barges.'+scope.$index+'.dateRange'" :rules="rules.reqRule">
                <el-date-picker
                  v-model="scope.row.dateRange"
                  @change="(e)=>hanleChangeDateRange(e,scope.$index)"
                  style="width: 100%"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :disabled="isAllFieldsDisabled"
                  :picker-options="pickerOptions()"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="" width="90px" fixed="right"  v-if="termContractType === TERM_CONTRACT_TYPE.COA">
            <template v-slot="scope">
              <el-popconfirm
                confirm-button-text='Delete'
                cancel-button-text='No, Thanks'
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure to delete this?"
                @confirm="removeLine(scope.$index)"
              >
                <el-button slot="reference" size="small" icon="el-icon-delete" type="danger" circle :disabled="isAllFieldsDisabled || bargeFormData.barges.length === 1"></el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-form>
</template>
<script>
import { TERM_CONTRACT_TYPE } from '@/constants.js'
import { sortByAlphabetical, getTodayISOString, checkStartEndDate, convertToQuery } from '@/utils'
import { checkBargeAvailability } from '@/services/modules/term.js'
export default {
  name: 'BargeInformation',
  props: {
    title: String,
    termContractType: String,
    rules: Object,
    isAllFieldsDisabled: Boolean,
    isEdit: Boolean,
    formData: Array,
    bargeOptionList: Array,
    entityData: Object
  },
  mounted () {
    if (this.isEdit && this.formData) {
      this.bargeFormData.barges = this.formData
      this.formData.forEach((barge, index) => {
        const dateRange = [barge.startDate, barge.endDate]
        this.$set(this.bargeFormData.barges[index], 'dateRange', dateRange)
      })
    }
  },
  data () {
    const initBargeData = {
      id: null,
      name: null,
      imoNo: null,
      sbNo: null,
      // isEnabled: true,
      // startDate: getTodayISOString(),
      // endDate: getTodayISOString(),
      dateRange: [getTodayISOString(), getTodayISOString()]
    }
    return {
      TERM_CONTRACT_TYPE,
      initBargeData,
      bargeFormData: {
        barges: [this.$_.cloneDeep(initBargeData)]
      }
    }
  },
  methods: {
    sortByAlphabetical,
    convertToQuery,
    pickerOptions () {
      const disabledDate = (time) => {
        return time.getTime() < new Date(this.entityData.contractStartDate).getTime() || time.getTime() > new Date(this.entityData.contractEndDate).getTime()
      }
      return { disabledDate }
    },
    addNewLine () {
      this.bargeFormData.barges.push(this.$_.cloneDeep(this.initBargeData))
    },
    removeLine (index) {
      this.bargeFormData.barges.splice(index, 1)
    },
    handleSelectBarge (vesselId, index) {
      const selectedBarge = this.bargeOptionList.filter(vessel => vessel.id === vesselId)[0]
      const _params = {
        imoNo: selectedBarge?.imo,
        startDate: this.entityData?.contractStartDate,
        endDate: this.entityData?.contractEndDate,
        termContractType: this.termContractType,
        termContractId: this.entityData?.id,
        shipId: vesselId
      }
      const checkDate = checkStartEndDate(_params.startDate, _params.endDate)
      if (this.entityData && checkDate) {
        const paramsQuery = convertToQuery(_params)

        checkBargeAvailability(paramsQuery).then(res => {
          if (res.code === 1000 && res.data) {
            this.bargeFormData.barges[index].name = selectedBarge.shipName
            this.bargeFormData.barges[index].imoNo = selectedBarge?.imo
            this.bargeFormData.barges[index].sbNo = selectedBarge?.licenceNo
            if (this.termContractType === this.TERM_CONTRACT_TYPE.TC) {
              this.bargeFormData.barges[index].startDate = this.entityData.contractStartDate
              this.bargeFormData.barges[index].endDate = this.entityData.contractEndDate
            }
          } else {
            this.bargeFormData.barges[index].imoNo = null
            this.bargeFormData.barges[index].sbNo = null
            this.bargeFormData.barges[index].id = null
            this.bargeFormData.barges[index].name = null
            const errMsg = res.errors ? res.errors : 'Please check and try anther barge.'
            const title = `${selectedBarge.shipName} Is Not Available`
            this.$alert(`${errMsg}`, `${title}`, { confirmButtonText: 'Confirm' })
          }
        })
      } else {
        this.bargeFormData.barges[index].imoNo = null
        this.bargeFormData.barges[index].sbNo = null
        this.bargeFormData.barges[index].id = null
        this.bargeFormData.barges[index].name = null
        const errMsg = 'Barge start date and and end date must be within contract start date and end date'
        const title = 'Check Barge Availability Failed'
        this.$alert(`${errMsg}, please check and try again.`, `${title}`, { confirmButtonText: 'Close' })
      }
    },
    getBargeFormData () {
      let isValid
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          isValid = true
        } else {
          isValid = false
          return null
        }
      })
      if (isValid) {
        this.bargeFormData.barges.forEach((barge) => {
          barge.shipId = barge.id
        })
        return this.bargeFormData.barges
      }
    },
    hanleChangeDateRange (val, index) {
      this.$set(this.bargeFormData.barges[index], 'startDate', val[0])
      this.$set(this.bargeFormData.barges[index], 'endDate', val[1])
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
  // as same as @import "../../../order/index.scss";
  .div-product-other-costs-info-body {
    >h4 {
      justify-self: left;
      margin: 0;
      margin-top: 20px;
    }
    .h4-btm {
      margin-top: 40px;
    }
    .el-table {
      width: 100%;
      .h5-el-table-col-index {
          color: #909399;
          margin: 0;
          margin-bottom: 20px;
          text-align: center;
      }
    }
    .el-button {
      margin-right: 20px;
      width: fit-content;
      justify-self: right;
      margin-bottom: 10px;
      float:right; // added for this table
    }
}
.div-table-col-product-pricing {
    text-align: left;
    >p {
        color: #0F376A;
        font-weight: 500;
        font-size: 16px;
        margin: 10px 0;
    }
    >div {
        display: flex;
        .el-form-item {
            margin-right: 10px;
        }
        .el-form-item-price-premium {
            margin-right: 10px;
            display: grid;
            text-align-last: left;
            .div-price-premium {
                display: flex;
                width: 260px;
                .el-select.unit-metric {
                    width: 150px
                }
            }
        }
        .el-checkbox {
            padding-top: 40px;
        }
    }
}
</style>
